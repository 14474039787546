import { Typography, PageHeader } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const HomePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t`app.home`} />
      <Title level={3}>WIP</Title>
    </>
  );
};

export default HomePage;
