import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useMemo } from "react";

import config from "../../config";
import { useAccessKeyContext } from "../contexts/AccessKey.context";

type UseAxiosReturnType = {
  axiosInstance: AxiosInstance;
  loadRequestInterceptor(
    successCallback: (request: AxiosRequestConfig) => void,
    errorCallback: (err: unknown) => void
  ): void;
  loadResponseInterceptor(
    successCallback: (request: AxiosResponse) => void,
    errorCallback: (err: unknown) => void
  ): void;
  createAxiosInstance: () => AxiosInstance;
};

export default (): UseAxiosReturnType => {
  const { accessKey } = useAccessKeyContext();
  const { backendUrl } = config;

  const axiosInstance = useMemo(() => {
    const axiosConfig: AxiosRequestConfig = {
      baseURL: backendUrl,
    };
    const instance = axios.create(axiosConfig);
    if (accessKey) {
      instance.defaults.headers.common.Authorization = accessKey;
    }

    return instance;
  }, [backendUrl, accessKey]);

  const loadRequestInterceptor = (
    successCallback: (request: AxiosRequestConfig) => void,
    errorCallback: (err: unknown) => void
  ): void => {
    axiosInstance.interceptors.request.use(
      (request: AxiosRequestConfig) => {
        successCallback(request);
        return request;
      },
      (err) => {
        errorCallback(err);
        return Promise.reject(err);
      }
    );
  };

  const loadResponseInterceptor = (
    successCallback: (request: AxiosResponse) => void,
    errorCallback: (err: unknown) => void
  ): void => {
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        successCallback(response);
        return response;
      },
      (err) => {
        errorCallback(err);
        return Promise.reject(err);
      }
    );
  };

  const createAxiosInstance = (): AxiosInstance => {
    return axios.create();
  };

  return {
    axiosInstance,
    loadRequestInterceptor,
    loadResponseInterceptor,
    createAxiosInstance,
  };
};
