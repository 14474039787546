import { message } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import useFetch from "../../shared/hooks/useFetch";
import { Product } from "../../shared/models/product";
import ProductForm, { FieldData } from "./ProductForm";

const ProductCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fields, setFields] = useState<FieldData[]>([]);
  const { post: postProduct } = useFetch({
    path: config.apiRoutes.product,
  });

  const handleCreate = useCallback(
    async (values: Product) => {
      const createResult = await postProduct(values);
      message.success(t`app.saveSuccessful`);
      navigate(`${config.apiRoutes.product}/${createResult.data.id}`, {
        replace: true,
      });
    },
    [postProduct, t, navigate]
  );

  return (
    <ProductForm
      fields={fields}
      onChange={(newFields) => {
        setFields(newFields);
      }}
      onSave={handleCreate}
    />
  );
};

export default ProductCreate;
