import { PageHeader, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t`app.login`} />
      <Row>
        <Col span={24} md={{ offset: 8, span: 8 }}>
          <LoginForm />
        </Col>
      </Row>
    </>
  );
};

export default LoginPage;
