import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Menu } from "antd";
import s from "./Sidebar.module.scss";
import config, { RouteItem } from "../../config";
import { useUserContext } from "../../shared/contexts/User.context";

const { Sider } = Layout;
const { routes } = config;
const navbarItems = Object.values(routes).filter(
  (item: RouteItem) => item.inNavbar
);

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoginPage = useMatch(config.routes.login.path);
  const { logout } = useUserContext();

  if (isLoginPage) {
    return null;
  }

  return (
    <Sider breakpoint="lg" collapsedWidth="0">
      <div className={s.logo} />
      <Menu
        theme="dark"
        mode="vertical"
        items={navbarItems.map((item: RouteItem) => {
          return {
            key: item.label,
            label: item.label,
            onClick: () => {
              navigate(item.path, { replace: true });
            },
          };
        })}
      />
      <div className={s.secondary}>
        <Menu
          theme="light"
          mode="vertical"
          items={[
            {
              key: "logout",
              label: t`app.logout`,
              onClick: logout,
            },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
