import { Routes as BrowserRoutes, Route } from "react-router-dom";

import HomePage from "./components/Home/HomePage";
import LoginPage from "./components/Login/LoginPage";
import ProductCreatePage from "./components/Product/ProductCreatePage";
import ProductEditPage from "./components/Product/ProductEditPage";
import ProductListPage from "./components/Product/ProductListPage";

import config from "./config";

const {
  routes: { home, login, product, productCreate },
} = config;

const Routes: React.FC = () => {
  return (
    <BrowserRoutes>
      <Route path={home.path} element={<HomePage />} />
      <Route path={login.path} element={<LoginPage />} />
      <Route path={product.path} element={<ProductListPage />} />
      <Route path={productCreate.path} element={<ProductCreatePage />} />
      <Route path={product.path}>
        <Route path=":productId" element={<ProductEditPage />} />
      </Route>
    </BrowserRoutes>
  );
};

export default Routes;
