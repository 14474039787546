import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { useAccessKeyContext } from "../../shared/contexts/AccessKey.context";
import useFetch from "../../shared/hooks/useFetch";
import { Product } from "../../shared/models/product";
import ProductForm, { FieldData } from "./ProductForm";

type Props = { id: number };

const ProductEdit: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const { get: getProduct } = useFetch({
    path: `${config.apiRoutes.product}/${id}`,
  });

  const { accessKey } = useAccessKeyContext();

  const [fields, setFields] = useState<FieldData[]>([]);

  const { patch: patchProduct } = useFetch({
    path: `${config.apiRoutes.product}/${id}`,
  });

  const handleSave = useCallback(
    async (values: Product) => {
      await patchProduct(values);

      message.success(t`app.saveSuccessful`);
    },
    [patchProduct, t]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProduct = await getProduct();

      setFields([
        { name: ["name"], value: fetchedProduct.data.name },
        { name: ["description"], value: fetchedProduct.data.description },
        { name: ["price"], value: fetchedProduct.data.price },
        { name: ["stock"], value: fetchedProduct.data.stock },
      ]);
    };

    if (accessKey) {
      fetchProducts();
    }
  }, [getProduct, accessKey]);

  return (
    <ProductForm
      fields={fields}
      onChange={(newFields) => {
        setFields(newFields);
      }}
      onSave={handleSave}
    />
  );
};

export default ProductEdit;
