import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import Routes from "./routes";
import Sidebar from "./components/Sidebar/Sidebar";
import "antd/dist/antd.css";
import { AccessKeyProvider } from "./shared/contexts/AccessKey.context";
import { UserProvider } from "./shared/contexts/User.context";

const { Content, Footer } = Layout;

const App: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AccessKeyProvider>
      <BrowserRouter>
        <UserProvider>
          <Layout>
            <Sidebar />
            <Layout>
              <Content>
                <div className="content-wrapper">
                  <Routes />
                </div>
              </Content>
              <Footer>
                {t`app.name`} ©{new Date().getFullYear()}
              </Footer>
            </Layout>
          </Layout>
        </UserProvider>
      </BrowserRouter>
    </AccessKeyProvider>
  );
};

export default App;
