import { useEffect, useState } from "react";
import { Space, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { useAccessKeyContext } from "../../shared/contexts/AccessKey.context";
import useFetch from "../../shared/hooks/useFetch";
import { Product } from "../../shared/models/product";

const { Paragraph } = Typography;

const LIST_COLUMNS = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Stock",
    dataIndex: "stock",
    key: "stock",
  },
  {
    title: "Action",
    key: "action",
    render: (_: number, record: Product) => (
      <Space key={record.id} size="middle">
        <Link to={`/product/${record.id}`}>Edit</Link>
      </Space>
    ),
  },
];

const ProductList: React.FC = () => {
  const { get: getProducts } = useFetch({
    path: config.apiRoutes.product,
  });
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[]>([]);
  const { accessKey } = useAccessKeyContext();

  useEffect(() => {
    const fetchProducts = async () => {
      const fetchedProducts = await getProducts();

      setProducts(
        fetchedProducts.data.map((item: Product) => ({
          ...item,
          ...{ key: item.id },
        }))
      );
    };

    if (accessKey) {
      fetchProducts();
    }
  }, [getProducts, accessKey]);

  return (
    <div>
      {products.length ? (
        <Table dataSource={products} columns={LIST_COLUMNS} />
      ) : (
        <Paragraph>{t`app.noProductsDescription`}</Paragraph>
      )}
    </div>
  );
};

export default ProductList;
