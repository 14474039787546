import LocalStorageEnum from "../consts/localStorageEnum";

export const getLocalStorageAccessKey = (): string | undefined => {
  return localStorage.getItem(LocalStorageEnum.ACCESS_KEY) || undefined;
};

export const setLocalStorageAccessKey = (accessKey: string) => {
  return localStorage.setItem(LocalStorageEnum.ACCESS_KEY, accessKey);
};

export const removeLocalStorageAccessKey = () => {
  return localStorage.removeItem(LocalStorageEnum.ACCESS_KEY);
};
