export type RouteItem = {
  path: string;
  label: string;
  inNavbar?: boolean;
};

type ConfigType = {
  backendUrl: string;
  apiRoutes: {
    authCheckKey: string;
    product: string;
  };
  routes: {
    login: RouteItem;
    home: RouteItem;
    product: RouteItem;
    productCreate: RouteItem;
  };
};

export default {
  backendUrl: `${
    process.env.REACT_APP_BACKEND_URL || "ADD_STAGING_API_HERE"
  }/api/v1`,
  apiRoutes: {
    authCheckKey: "/auth/check-key",
    product: "/product",
  },
  routes: {
    login: { path: "/login", label: "Login", inNavbar: false },
    home: { path: "/", label: "Home", inNavbar: true },
    product: { path: "/product", label: "Products", inNavbar: true },
    productCreate: {
      path: "/product/create",
      label: "Create Product",
      inNavbar: false,
    },
  },
} as ConfigType;
