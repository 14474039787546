import { Button, Form, Input, InputNumber } from "antd";
import { Product } from "../../shared/models/product";

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

interface ProductFormProps {
  onChange: (fields: FieldData[]) => void;
  onSave: (values: Product) => Promise<void>;
  fields: FieldData[];
}

const ProductForm: React.FC<ProductFormProps> = ({
  onChange,
  fields,
  onSave,
}) => {
  return (
    <Form
      onFinish={onSave}
      fields={fields}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="price" label="Price" rules={[{ required: true }]}>
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item name="stock" label="Stock" rules={[{ required: true }]}>
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
