import { PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import ProductCreate from "./ProductCreate";

const ProductCreatePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t`app.productCreate`} />
      <ProductCreate />
    </>
  );
};

export default ProductCreatePage;
