import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  getLocalStorageAccessKey,
  removeLocalStorageAccessKey,
  setLocalStorageAccessKey,
} from "../utilities/localStorage";

type AccessKeyContextType = {
  accessKey?: string;
  updateAccessKey: (newValue: string) => void;
  removeAccessKey: () => void;
  initAccessKey: () => boolean;
};

export const AccessKeyContext = createContext<AccessKeyContextType>(
  {} as AccessKeyContextType
);

type Props = { children: ReactNode };

export const AccessKeyProvider: React.FC<Props> = ({ children }) => {
  const [accessKey, setAccessKey] = useState<string>();

  const updateAccessKey = useCallback((newValue: string) => {
    setAccessKey(newValue);
    setLocalStorageAccessKey(newValue);
  }, []);

  const removeAccessKey = useCallback(() => {
    setAccessKey(undefined);
    removeLocalStorageAccessKey();
  }, []);

  const initAccessKey = useCallback(() => {
    const localStorageAccessKey = getLocalStorageAccessKey();

    if (!localStorageAccessKey) {
      return false;
    }

    setAccessKey(localStorageAccessKey);

    return true;
  }, []);

  const value: AccessKeyContextType = useMemo(
    () => ({
      accessKey,
      updateAccessKey,
      removeAccessKey,
      initAccessKey,
    }),
    [accessKey, removeAccessKey, updateAccessKey, initAccessKey]
  );

  return (
    <AccessKeyContext.Provider value={value}>
      {children}
    </AccessKeyContext.Provider>
  );
};

export const useAccessKeyContext = (): AccessKeyContextType => {
  return useContext(AccessKeyContext);
};
