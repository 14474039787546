import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../shared/contexts/User.context";

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { login } = useUserContext();

  return (
    <Form onFinish={login} autoComplete="off" layout="vertical">
      <Form.Item
        label="Access Key"
        name="accessKey"
        rules={[{ required: true, message: t`app.messageRequiredKey` }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
