import { message } from "antd";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import useFetch from "../hooks/useFetch";
import { LoginFormData } from "../models/loginFormData";
import { useAccessKeyContext } from "./AccessKey.context";

type UserContextType = {
  logout: () => void;
  login: (values: LoginFormData) => Promise<void>;
};

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

type Props = { children: ReactNode };

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { updateAccessKey, removeAccessKey, initAccessKey } =
    useAccessKeyContext();
  const navigate = useNavigate();
  const { post: postAuthCheckKey } = useFetch({
    path: config.apiRoutes.authCheckKey,
  });

  const login = useCallback(
    async (values: LoginFormData) => {
      const { data } = await postAuthCheckKey(values);
      if (data.success) {
        updateAccessKey(values.accessKey);
        message.success(t`app.loginSuccessful`);
        navigate(config.routes.home.path, { replace: true });

        return;
      }

      message.error(t`app.loginFailed`);
    },
    [postAuthCheckKey, updateAccessKey, navigate, t]
  );

  const logout = useCallback(() => {
    removeAccessKey();
    message.info(t`app.loggedOut`);
    navigate(config.routes.login.path, { replace: true });
  }, [removeAccessKey, navigate, t]);

  useEffect(() => {
    if (!initAccessKey()) {
      navigate(config.routes.login.path, { replace: true });
    }
  }, [initAccessKey, navigate]);

  const value: UserContextType = useMemo(
    () => ({
      logout,
      login,
    }),
    [logout, login]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
