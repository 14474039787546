import { Button, message, PageHeader } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import useFetch from "../../shared/hooks/useFetch";
import ProductEdit from "./ProductEdit";

const ProductEditPage: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const navigate = useNavigate();
  const { remove: removeProduct } = useFetch({
    path: `${config.apiRoutes.product}/${productId}`,
  });

  const handleDelete = useCallback(async () => {
    const removeResult = await removeProduct();
    if (removeResult.data.success) {
      navigate(config.routes.product.path, { replace: true });
      message.info(t`app.deleteSuccessful`);

      return;
    }

    message.error(t`app.deleteFailed`);
  }, [removeProduct, navigate, t]);

  if (!productId) {
    return <div />;
  }

  return (
    <>
      <PageHeader
        title={t`app.productEdit`}
        extra={[
          <Button danger key="delete" onClick={handleDelete}>
            Delete
          </Button>,
        ]}
      />
      <ProductEdit id={parseInt(productId, 10)} />
    </>
  );
};

export default ProductEditPage;
