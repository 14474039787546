import { Button, PageHeader } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import ProductList from "./ProductList";

const ProductListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={t`app.productList`}
        extra={[
          <Button
            key="create"
            onClick={() => {
              navigate(config.routes.productCreate.path, { replace: true });
            }}
          >
            Create
          </Button>,
        ]}
      />
      <ProductList />
    </>
  );
};

export default ProductListPage;
